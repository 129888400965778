import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'

// Components
import Layout from 'components/Layout'
import SEO from 'components/SEO'
import CustomBreadCrumb from 'components/Breadcrumb'
import Quality from 'components/Overons/Quality'
import Employees from 'components/Overons/Employees'

// Images
import logoRight from 'img/logo_pointing_right.svg'
import Snelheid from 'img/Snelheid.inline.svg'
import Duidelijkheid from 'img/Duidelijkheid.inline.svg'
import Kwaliteit from 'img/Kwaliteit.inline.svg'

// Third Party
import { each } from 'lodash'
import styled from 'styled-components'
import parse from 'html-react-parser'

const StyledImg = styled(Img)`
  pointer-events: none;
  height: 100%;
  z-index: -1;
`

const ImgContainer = styled.div`
  position: relative;
  height: 330px;

  @media screen and (max-width: 767px) {
    height: 230px;
  }

  @media screen and (max-width: 483px) {
    height: 170px;
  }
`

const StyledSnelheid = styled(Snelheid)`
  position: absolute;
  height: 60px;
  width: 60px;

  @media screen and (max-width: 767px) {
    left: 5rem;
  }

  @media screen and (max-width: 451px) {
    left: 2rem;
  }
`

const StyledKwaliteit = styled(Kwaliteit)`
  position: absolute;
  height: 60px;
  width: 60px;

  @media screen and (max-width: 767px) {
    left: 5rem;
  }

  @media screen and (max-width: 451px) {
    left: 2rem;
  }
`

const StyledDuidelijkheid = styled(Duidelijkheid)`
  position: absolute;
  height: 60px;
  width: 60px;

  @media screen and (max-width: 767px) {
    left: 5rem;
  }

  @media screen and (max-width: 451px) {
    left: 2rem;
  }
`

const ImageContainerText = styled.div`
  margin-top: -100px;
  color: ${props => props.theme.color.text.light};

  @media screen and (max-width: 767px) {
    margin-top: -60px;
  }
`

const OverOnsInformation = styled.div`
  h3 {
    margin: 0;
    font-size: ${props => props.theme.font.size.ms};
    font-weight: ${props => props.theme.font.weight.xl};
    color: ${props => props.theme.color.contrast};
  }
  
  p {
    font-size: ${props => props.theme.font.size.m};
    color: ${props => props.theme.color.main};
    line-height: 24px;
  }
`

const Quote = styled.div`
  p {
    font-family: ${props => props.theme.font.family.third};
    font-size: ${props => props.theme.font.size.xsl};
    color: ${props => props.theme.color.contrast};
  }
`

const OverOns = ({ pageContext }) => {
  const { 
    overOns: {
      title: pageTitle,
      path,
      acf: {
        banner_image: {
          localFile: {
            childImageSharp: {
              fluid: bannerImage
            }
          }
        },
        group_1: {
          content: group1content,
          quote: group1quote
        },
        points_column: pointsObject
      }
    },
    yoastMeta,
    medewerkers: {
      edges: medewerkers
    }
  } = useStaticQuery(graphql`
      {
        overOns: wordpressPage(wordpress_id: {eq: 149}) {
          title
          path
          acf {
            banner_image {
              localFile {
                childImageSharp {
                  fluid(quality: 100, maxWidth: 1920) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
            group_1 {
              content
              quote
            }
            points_column {
              aandacht {
                point
              }
              snelheid {
                point
              }
              duidelijk {
                point
              }
            }
          }
          yoastMeta: yoast_meta {
            name
            content
            property
          }
        }
        medewerkers: allWordpressWpMedewerkers(sort: {fields: date, order: ASC}) {
          edges {
            node {
              acf {
                quote
                photo {
                  localFile {
                    childImageSharp {
                      fluid(quality: 100, maxWidth: 1920) {
                        ...GatsbyImageSharpFluid_withWebp
                      }
                    }
                  }
                }
              }
              slug
              title
              wordpress_id
            }
          }
        }
      }
  `)

  const pointHeaders = []
  each(pointsObject, (p, index) => {
    pointHeaders.push({
      label: index.charAt(0).toUpperCase() + index.slice(1),
      value: index
    })
  })

  return (
    <Layout>
      <SEO yoast={{ meta: yoastMeta }} pathname={path} />
      <div className="over-ons">
        <ImgContainer>
          <StyledImg className="d-flex" alt="" fluid={bannerImage} />
          <ImageContainerText className="container">
            <div className="title font-size-xxl font-weight-xl">
              <h2 className="color-text-light font-weight-xl">Over ons</h2>
            </div>
          </ImageContainerText>
        </ImgContainer>
        <CustomBreadCrumb data={pageTitle} className="py-2" pageContext={pageContext} />
        <div className="over-ons-kwaliteiten py-5">
          <div className="container">
            <div className="row">
              <div className="col-12 col-md-6 d-flex flex-wrap justify-content-center align-items-center">
                <img alt="" src={logoRight} />
                <div className="w-100">
                  <p className="w-100 font-size-xm font-weight-xl text-center mb-1">Waarom kiest u voor</p>
                  <p className="w-100 font-size-xm font-weight-xl text-center">Zwemschool Snorkeltje</p>
                </div>
              </div>
              <div className="col-12 col-md-6 pt-5 pt-lg-0 d-flex flex-wrap justify-content-center align-items-center">
                {pointHeaders.map(p => (
                  <div>
                    {p.label === 'Snelheid' && ( <StyledSnelheid /> )}
                    {p.label === 'Aandacht' && ( <StyledKwaliteit /> )}
                    {p.label === 'Duidelijk' && ( <StyledDuidelijkheid /> )}
                    <Quality key={p.label} id={p.value} title={p.label} points={pointsObject[p.value]} />
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
        <OverOnsInformation className="color-background-beige py-5">
          <div className="container">
            <div className="row">
              <div className="col-12 col-lg-6">
                <h2 className="font-size-xxl pb-4 font-weight-xl text-center text-lg-left color-text-main">Zwemschool Snorkeltje</h2>
                <div>{parse(group1content)}</div>
              </div>
              <div className="col-12 col-lg-6 d-flex justify-content-center align-items-center">
                <Quote className="text-center pt-5 pt-lg-0">{parse(group1quote)}</Quote>
              </div>
            </div>
          </div>
        </OverOnsInformation>
        <a id="employees" />
        <Employees employees={medewerkers} />
        <div className="empty-space-200" />
      </div>
    </Layout>
  )
}

export default OverOns