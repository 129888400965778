/* eslint-disable react/no-array-index-key */
import React from 'react'

// Third Party
import styled from 'styled-components'

const StyledQuality = styled.div`
  padding-left: 3rem;

  @media screen and (max-width: 767px) {
    padding-left: 5rem;
    margin-left: 3rem;
  }

  @media screen and (max-width: 451px) {
    padding-left: 5rem;
    margin-left: 0rem;
    
  }

  p {
    position: relative;
  }
`

const Quality = ({ points, title, id, className }) => {
  return (
    <StyledQuality
      id={id}
      className={`w-100 d-flex flex-wrap ${className ? `${className}` : ``}`}
    >
      <h1 className="font-size-m pl-2 pl-md-5 mb-0 font-weight-xl color-text-contrast w-100">
        {title}
      </h1>
      <div className="col-lg-9 d-flex pl-4 justify-content-center mb-3 flex-wrap">
        {points.map((sub, index) => (
          <p className="pl-2 pl-md-4 m-0 w-100" key={index}>
            <em>{sub.point}</em>
          </p>
        ))}
      </div>
    </StyledQuality>
  )
}

export default Quality
