import React from 'react'
import Img from 'gatsby-image'

// Images
import QuoteIcon from 'img/quote.inline.svg'

// Third Party
import styled from 'styled-components'
import parse from 'html-react-parser'

const ImageContainer = styled.div`
  height: auto;
  margin-bottom: 10px;
  border-radius: 5px;
  max-width: 150px;
  overflow: hidden;

  p {
    position: relative;
    width: 100%;
    z-index: 10;
    margin-top: -10px;
    padding-top: 3px;
    padding-bottom: 3px;
    background-color: ${props => props.theme.color.blue};
    border-radius: 5px;
    text-align: left;
  }
`

const StyledImg = styled(Img)`
  border-radius: 100px;
  width: 150px !important;
  height: 150px !important;
  z-index: 5;
`

const StyledEmployees = styled.div``

const StyledQuoteIcon = styled(QuoteIcon)`
  position: absolute;
  top: 10px;
  left: 30px;
  height: 40px;
  width: 45px;
  z-index: 2;

  @media screen and (max-width: 991px) {
    top: -20px;
  }
`

const Quote = styled.div`
  position: relative;
  background-color: ${props => props.theme.color.beige};
  padding: 40px 30px 30px 30px;
  border-radius: 5px;
  float: left;

  p {
    margin: 0;
  }

  @media screen and (max-width: 991px) {
    width: 100%;
  }

  &::after {
    content: '';
    position: absolute;
    left: 0;
    top: 70%;
    width: 0;
    height: 0;
    border: 12px solid transparent;
    border-right-color: ${props => props.theme.color.beige};
    border-left: 0;
    margin-top: -12px;
    margin-left: -12px;
  }
`

const Employees = ({ employees, className }) => {
  return (
    <StyledEmployees className={`container ${className ? `${className}` : ``}`}>
      <h2 className="font-size-xxl font-weight-xl text-lg-left text-center color-text-main py-5">Maak kennis met onze instructeurs</h2>
      {employees.map((emp, index) => (
        <div key={emp.node.wordpress_id} className={`row mb-3 ${index % 2 === 0 ? `justify-content-center justify-content-lg-start` : `justify-content-end`}`}>
          <div className={`${index % 2 === 0 ? `col-12 col-lg-2 d-flex justify-content-center` : `col-12 col-lg-5 d-flex justify-content-center justify-content-lg-end`}`}>
            <ImageContainer>
              <StyledImg alt="" fluid={emp.node.acf.photo.localFile.childImageSharp.fluid} />
              <p className="font-weight-xl color-text-light font-size-s text-center">{emp.node.title}</p>
            </ImageContainer>
          </div>
          <div className={`position-relative d-flex align-items-center ${index % 2 === 0 ? `col-12 col-lg-10 justify-content-center justify-content-lg-start` : `col-12 col-lg-7 justify-content-center justify-content-lg-start`}`}>
            <StyledQuoteIcon />
            <Quote>{parse(emp.node.acf.quote)}</Quote>
          </div>
        </div>
    ))}
    </StyledEmployees>
  )
}

export default Employees